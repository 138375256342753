<template>
  <div>
    <v-row dense class="pa-2">
      <v-col cols="12" sm="6" md="3" v-for="item in direction_sites" :key="item.id">
        <v-card outlined>
          <v-card-title class="py-2 pl-2 pr-0">
            {{ item.direction_site_name }}
            <v-spacer></v-spacer>
            <v-btn icon v-if="loginUserInfo.is_admin_user" @click="openEditDialog(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-img :src="item.preview_image_url" width="auto" height="200" position="top center">
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
              </div>
            </template>
          </v-img>
          <v-divider></v-divider>
          <v-card-actions class="pa-2">
            <template v-if="item.direction_site_approval"><v-icon color="success" class="mr-1">mdi-checkbox-marked-circle-outline</v-icon>承認</template>
            <template v-else>
              <confirm-dialog @accept="approvalDirectionSite(item)" type="success" btnTitle="承認する">
                <template v-slot:title>このデザインを承認しますか？</template>
                <template v-slot:message><b>「{{ item.direction_site_name }}」</b>をご確認いただき、問題なければ<b>「承認する」</b>を、修正などございましたら<b>「キャンセル」</b>をクリックして、コメントよりお知らせください。</template>
                <template v-slot:activator="{ on }">
                  <v-btn @click.stop="on" block text class="px-2" style="font-size: 1rem;"><v-icon color="gray" class="mr-1">mdi-checkbox-marked-circle-outline</v-icon>デザインのご承認</v-btn>
                </template>
              </confirm-dialog>
            </template>
            <v-spacer></v-spacer>
            <v-btn v-if="item.direction_site_design_file" :href="item.direction_site_design_file.file_url" target="_blank" rel="noopener" text size="20">開く<v-icon small>mdi-open-in-new</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="crud.dialog" max-width="500px">
      <v-form ref="form" v-model="crud.isFormValid" @submit.prevent>
        <v-card>
          <v-card-title v-if="crud.mode == 'new'">デザイン追加</v-card-title>
          <v-card-title v-else-if="crud.mode == 'edit'">デザイン編集</v-card-title>
          <v-divider />
          <v-card-text>
            <v-container class="pt-0">
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-text-field label="ページ名" type="text" v-model="crud.form.direction_site_name" :rules="[inputRules.required]" class="required"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-file-input label="ファイル添付" v-model="crud.form.direction_site_design_file" :rules="[]" class="my-0 py-0" small-chips show-size persistent-hint></v-file-input>
                </v-col>
                <v-col cols="12" md="12">
                  <v-checkbox label="承認チェック" v-model="crud.form.direction_site_approval" :false-value="0" :true-value="1" color="success" dense hide-details class="pa-0 ma-0"></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="" text @click="closeDialog()">キャンセル</v-btn>
            <v-btn v-if="crud.mode == 'new'" v-promise-btn color="primary" @click="addDirectionSite()" :disabled="!crud.isFormValid">保存</v-btn>
            <v-btn v-else-if="crud.mode == 'edit'" color="primary" v-promise-btn @click="updateDirectionSite()" :disabled="!crud.isFormValid">保存</v-btn>
            <v-spacer />
            <confirm-dialog @accept="removeDirectionSite()" v-if="crud.editedId !== -1">
              <template v-slot:title>このデザインを削除しますか？</template>
              <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
              </template>
            </confirm-dialog>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  name: 'DirectionSite',
  props: [
    'id'
  ],
  components: {
    ConfirmDialog
  },
  data: () => ({
    table_headers: [
      { text: 'デザイン', value: 'direction_site_name' },
      { text: '承認', value: 'direction_site_approval', width: 150 },
      { text: '', value: 'actions', cellClass: 'action' }
    ],
    direction_sites: [],
    crud: {
      mode: 'new',
      dialog: false,
      editedId: -1,
      isFormValid: false,
      form: {
        direction_site_name: '',
        direction_site_approval: 0,
        direction_site_design_file: null
      },
      defaultItem: {
        direction_site_name: '',
        direction_site_approval: 0,
        direction_site_design_file: null
      }
    }
  }),
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    }
  },
  created: function () {
    this.readDataFromAPI()
  },
  methods: {
    readDataFromAPI: function () {
      const self = this
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_site`)
        .then(res => {
          self.direction_sites = res.data
        })
    },
    openEditDialog (item) {
      this.crud.mode = 'edit'
      this.crud.editedId = item.id
      this.crud.form = Object.assign({}, item)
      this.crud.form.direction_site_design_file = this.crud.defaultItem.direction_site_design_file
      this.crud.dialog = true
    },
    openAddDialog () {
      this.crud.mode = 'new'
      this.crud.editedId = -1
      this.crud.form = Object.assign({}, this.crud.defaultItem)
      this.crud.dialog = true
    },
    closeDialog () {
      this.crud.editedId = -1
      this.crud.form = Object.assign({}, this.crud.defaultItem)
      this.crud.dialog = false
    },
    addDirectionSite: function () {
      const self = this
      if (self.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'POST')
        formData.append('direction_site_name', self.crud.form.direction_site_name ?? '')
        formData.append('direction_site_approval', self.crud.form.direction_site_approval ?? '')
        if (self.crud.form.direction_site_design_file) {
          const file = self.crud.form.direction_site_design_file
          formData.append('direction_site_design_file', file, file.name)
        }
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_site`, formData)
            .then((res) => {
              self.$store.commit('setFlashSuccess', 'デザインを登録しました。')
              resolve(res)
            })
            .catch(err => {
              self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              self.readDataFromAPI()
              self.closeDialog()
              self.$emit('change')
            })
        })
      }
    },
    updateDirectionSite: function () {
      const self = this
      if (self.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_site_name', self.crud.form.direction_site_name ?? '')
        formData.append('direction_site_approval', self.crud.form.direction_site_approval ?? '')
        if (self.crud.form.direction_site_design_file) {
          const file = self.crud.form.direction_site_design_file
          formData.append('direction_site_design_file', file, file.name)
        }
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_site/${self.crud.editedId}`, formData)
          .then(() => {
            self.$store.commit('setFlashSuccess', 'デザインを更新しました。')
          })
          .catch(err => {
            self.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            self.readDataFromAPI()
            self.closeDialog()
            self.$emit('change')
          })
      }
    },
    approvalDirectionSite: function (item) {
      const self = this
      const formData = new FormData()
      formData.append('_method', 'PUT')
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_site/${item.id}/approval`, formData)
        .then(() => {
          self.$store.commit('setFlashSuccess', 'デザインを承認しました。')
        })
        .catch(err => {
          self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
        })
        .finally(() => {
          self.readDataFromAPI()
          self.closeDialog()
          self.$emit('change')
        })
    },
    removeDirectionSite: function () {
      const self = this
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_site/${self.crud.editedId}`)
        .then(() => {
          self.$store.commit('setFlashSuccess', 'デザインを削除しました。')
        })
        .catch(err => {
          self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
        })
        .finally(() => {
          self.readDataFromAPI()
          self.closeDialog()
          self.$emit('change')
        })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep td.action {
  width: 30px;
}
::v-deep .v-data-table .v-chip {
  vertical-align: baseline;
}
</style>
