<template>
  <div>
    <v-card style="box-shadow: none;">
      <v-data-table disable-sort :headers="table_headers" :items-per-page="-1" :items="direction_files" class="elevation-0" hide-default-footer no-data-text="データは登録されていません">
        <template v-slot:item.original_name="{ item }">
          <v-icon v-if="item.pinned_at" left color="accent" class="-rotate-45 align-baseline">mdi-pin</v-icon>
          <FileAttachmentChip :file="item" :small="false"></FileAttachmentChip>
        </template>
        <template v-slot:item.size="{ item }">
          <template v-if="item.size">
            {{ item.size | prettyBytes(1) }}
          </template>
        </template>
        <template v-slot:item.create_at="{ item }">
          {{ item.create_at | moment("YYYY/MM/DD HH:mm") }}
        </template>
        <template v-slot:item.submit_login_id="{ item }">
          <template v-if="item.submit_login">
            <div style="white-space: nowrap;">
              <LoginAvatar :user="item.submit_login" :size="32" :avatarClass="['d-inline-block','mr-1']" />
              {{ item.submit_login.name }}
            </div>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu v-if="loginUserInfo.is_admin_user" offset-y min-width="160">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon small>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-if="!item.pinned_at" @click="enablePinnedDirectionFile(item)">
                <v-list-item-icon><v-icon small>mdi-pin</v-icon></v-list-item-icon>ピン留めする
              </v-list-item>
              <confirm-dialog @accept="disablePinnedDirectionFile(item)" type="info" btnTitle="ピン留めを外す">
                <template v-slot:title>このメッセージのピン留めを解除しますか？</template>
                <template v-slot:message>リストの上部の固定表示が解除されます。</template>
                <template v-slot:activator="{ on }">
                  <v-list-item v-if="item.pinned_at" @click.stop="on">
                    <v-list-item-icon><v-icon small>mdi-pin-off</v-icon></v-list-item-icon>ピン留めを外す
                  </v-list-item>
                </template>
              </confirm-dialog>
              <confirm-dialog @accept="removeDirectionFile(item)">
                <template v-slot:title>このデータを削除しますか？</template>
                <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
                <template v-slot:activator="{ on }">
                  <v-list-item @click.stop="on">
                    <v-list-item-icon><v-icon small>mdi-delete-forever</v-icon></v-list-item-icon>削除
                  </v-list-item>
                </template>
              </confirm-dialog>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog persistent v-model="crud.dialog" max-width="400px">
      <v-form ref="form" v-model="crud.isFormValid" @submit.prevent>
        <v-card>
          <v-card-title>ファイル追加</v-card-title>
          <v-divider />
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-file-input label="ファイル添付" v-model="crud.form.files" :rules="crud.form.files_rules" class="required my-0 py-0" small-chips multiple counter show-size hint="複数選択可" persistent-hint></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="" text @click="closeDialog()">キャンセル</v-btn>
            <v-btn v-promise-btn color="primary" @click="addDirectionFile()" :disabled="!crud.isFormValid">保存</v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FileAttachmentChip from '@/components/FileAttachmentChip.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  name: 'DirectionFile',
  props: [
    'id'
  ],
  components: {
    ConfirmDialog,
    FileAttachmentChip,
    LoginAvatar
  },
  data: () => ({
    table_headers: [
      { text: '名前', value: 'original_name' },
      { text: 'サイズ', value: 'size' },
      { text: '登録者', value: 'submit_login_id' },
      { text: '登録日', value: 'create_at' },
      { text: '', value: 'actions', cellClass: 'action' }
    ],
    direction_files: [],
    crud: {
      dialog: false,
      isFormValid: false,
      form: {
        files: [],
        files_rules: [
          files => files.length > 0 || 'ファイルを選択してください',
          files => !files.some(file => file.size > (150 * 1000 * 1000)) || 'サイズが大きすぎます' // NOTE: ディレクション管理は150MBまでに上限拡張
        ]
      }
    }
  }),
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    }
  },
  created: function () {
    this.readDataFromAPI()
  },
  methods: {
    readDataFromAPI: function () {
      const self = this
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/file_attachment`)
        .then(res => {
          self.direction_files = res.data
        })
    },
    openAddDialog () {
      this.crud.form.files = []
      this.crud.dialog = true
    },
    closeDialog () {
      this.crud.form.files = []
      this.crud.dialog = false
    },
    addDirectionFile: function () {
      const self = this
      if (self.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'POST')
        if (self.crud.form.files) {
          for (const file of self.crud.form.files) {
            formData.append('files[]', file, file.name)
          }
        }
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/file_attachment`, formData)
            .then((res) => {
              self.$store.commit('setFlashSuccess', 'ファイルを登録しました。')
              resolve(res)
            })
            .catch(err => {
              self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              self.readDataFromAPI()
              self.closeDialog()
              self.$emit('change')
            })
        })
      }
    },
    enablePinnedDirectionFile: function (item) {
      const self = this
      const formData = new FormData()
      formData.append('_method', 'PUT')
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/file_attachment/${item.id}/enable_pin`, formData)
        .then(() => {
          self.$store.commit('setFlashSuccess', 'ファイルをピン留めしました。')
        })
        .catch(err => {
          self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
        })
        .finally(() => {
          self.readDataFromAPI()
          self.closeDialog()
          self.$emit('change')
        })
    },
    disablePinnedDirectionFile: function (item) {
      const self = this
      const formData = new FormData()
      formData.append('_method', 'PUT')
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/file_attachment/${item.id}/disable_pin`, formData)
        .then(() => {
          self.$store.commit('setFlashSuccess', 'ファイルのピン留めを解除しました。')
        })
        .catch(err => {
          self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
        })
        .finally(() => {
          self.readDataFromAPI()
          self.closeDialog()
          self.$emit('change')
        })
    },
    removeDirectionFile: function (item) {
      const self = this
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/file_attachment/${item.id}`)
        .then(() => {
          self.$store.commit('setFlashSuccess', 'ファイルを削除しました。')
        })
        .catch(err => {
          self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
        })
        .finally(() => {
          self.readDataFromAPI()
          self.closeDialog()
          self.$emit('change')
        })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep td.action {
  width: 30px;
}
::v-deep .v-data-table .v-chip {
  vertical-align: baseline;
}
</style>
