<template>
  <v-tooltip v-if="file" top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-bind="attrs" v-on="on" :href="file.file_url" target="_blank" rel="noopener" :small="small" outlined><v-icon :small="small" left>mdi-paperclip</v-icon>{{ file.original_name }}</v-chip>
    </template>
    <v-img v-if="file.preview_image_url" :src="file.preview_image_url" :alt="file.original_name" max-height="200" max-width="200" position="top center" class="my-2 mb-1">
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
        </div>
      </template>
    </v-img>
    ダウンロード({{ file.size | prettyBytes(1) }})
  </v-tooltip>
</template>

<script>
export default {
  name: 'FileAttachmentChip',
  props: {
    file: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: true
    }
  }
}
</script>
